.no-records {
  position: absolute;
  width: 100% !important;
  text-align: center;
  margin: 0.5% 0%;
  font-size: 24px;
}

.total-vehicle {
  text-align: center !important;
  position: absolute;
  border: 1px solid #3b98d1 !important;
  width: 100% !important;
  padding: 0.3% 0% !important;
  background-color: #f2f8fe !important;
  font-weight: 600 !important;
  margin-bottom: 1% !important;
}

.filterButton {
  border: 2px solid #eaeced;
  color: #3b98d1;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
  margin-left: 1%;
  height: 40px;
  padding: 0% 0.5%;
  border-radius: 10%;
  background: none;
}
