.btn-delete{
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    width: fit-content;
    font-size: 16px;

}

.btn-mark{
    background-color: #3b98d1;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    margin-right: 10px;
    font-size: 16px;
}

.notification-text{
    font-weight: 600;
    font-size: 18px;
}

.notification-div{
    padding: 0.5% 5px;
}


.notification-div-unseen{
    background-color: #f0f0f0;
}